<template>
  <v-container class="fill-height fullscreen-bg" fluid> 
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-card-title class="headline">Connect</v-card-title>
          <div class="provider-container">
            <div class="ma-4 mb-0 provider provider-connect">
              <v-btn block color="#2EB67D" class="white--text" @click="loginWithMicrosoft" :loading="$store.getters.isLoading">
                <v-icon class="mr-2">fa-users</v-icon>
                <span style="font-size:70%;">Sign-In with Teams</span>
              </v-btn>
              <hr class="mt-8" />
            </div>
            <v-card-text class="provider pb-0" >
              <v-alert v-if="error" type="error">{{error}}</v-alert>
              <h3>Login for existing users</h3>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field 
                  v-model="email" 
                  :rules="emailRules" 
                  :label="$t('account.register.email')" 
                  prepend-icon="fa-envelope"
                  required>
              </v-text-field>

                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('account.register.password')"
                  required
                  prepend-icon="fa-lock"
                  :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  @click:append="passwordShow = !passwordShow"
                ></v-text-field>

              </v-form>
              <v-btn block :disabled="!valid" color="primary" @click="validate">{{$t('account.login.submit')}}</v-btn>
              <v-btn text class="mt-2" color="primary" to="/account/passwordrecovery" :loading="$store.getters.isLoading">{{$t('account.login.forgot-your-password')}}</v-btn>
            </v-card-text>
            

          </div>
          <i18n path="account.register.terms_privacy" tag="v-card-text" class="text-muted">
            <router-link to='termsofservice'>{{$t('account.register.terms_privacy_terms')}}</router-link>
            <router-link to='privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</router-link>
          </i18n>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import accountService from "@/services/accountService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
const tenant = tenants.current();

export default {
  components: {
  },
  props: {
  },
  data: () => ({
    providers: tenant.accountProviders,
    providerUrls: null,
    event: null,
    passwordShow: false,
    valid: true,
    error: null,
    errorCode: null,
    email: "",
    emailRules: [
      v => !!v || i18n.t('account.register.email-is-required'),
      v => /.+@.+/.test(v) || i18n.t('account.register.email-must-be-valid')
    ],
    password: "",
    passwordRules: [v => !!v || i18n.t('account.register.password-is-required')]
  }),

  async mounted() {
    EventBus.$on('login-state-change', async user => {
      if (user) {
        await this.linkWithTeams();
      }
    });
  },

  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
        this.loginWithFirebase();
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    async loginWithMicrosoft() {
      let authorizeEndpoint = `https://login.microsoftonline.com/${this.$route.query.tenant_id}/oauth2/authorize?${this.toQueryString(this.$route.query)}`;
      window.location.assign(authorizeEndpoint);
    },

    async linkWithTeams() {
      try {
        console.log('Teams linking', this.$route.query.code, this.$route.query.scope);
        if (this.$route.query.code) {
          var response = await accountService.linkTeams(this.$route.query.code, this.$route.query.scope);
          console.log('Teams redirect', response.data, this.$route.query.redirect_uri);
          //window.location.href = response.data;
          //let authorizeEndpoint = `https://login.microsoftonline.com/${context.tid}/oauth2/authorize?${this.toQueryString(queryParams)}`;
          let callbackUrl = this.$route.query.redirect_uri + `#code=${response.code}&state=${this.$route.query.state}`;
          console.log('Redirecting back', callbackUrl);
          window.location.assign(callbackUrl);
          //window.location.replace(new_href);
        }
      } catch (ex) {
        this.error = "Could not link with Teams";
      }
    },
    async redirectOauthResponse(code) {
        //window.location.href = response.data;
        //let authorizeEndpoint = `https://login.microsoftonline.com/${context.tid}/oauth2/authorize?${this.toQueryString(queryParams)}`;
        let callbackUrl = this.$route.query.redirect_uri + `#code=${code}&state=${this.$route.query.state}`;
        console.log('Redirecting back', callbackUrl);
        window.location.assign(callbackUrl);
    },

    async loginWithFirebase() {
      const auth = getAuth();
      const user = {
        email: this.email,
        password: this.password
      };
      try {
        var response = await signInWithEmailAndPassword(auth, user.email, user.password);
        
        if (response && response.user) {
          console.log('Logged in as ', response.user);
          await this.$store.dispatch("setUser", response.user);
          this.$analytics.event('login', { method : 'Email' });

          var tokenResponse = await accountService.createToken();
          console.log('Retrieved code ', tokenResponse.data);
          await this.redirectOauthResponse(tokenResponse.data.token)
        }
        this.linkWithTeams();
      } catch (ex) {
        this.error = this.$t('account.login.invalid-credentials');
      }
    },

    toQueryString(queryParams) {
        let encodedQueryParams = [];
        for (let key in queryParams) {
            encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
        }
        return encodedQueryParams.join("&");
    },    
  },
  computed: {
    isRedirecting() {
      return this.$route.query.token;
    },
    viewMode() {
      // note: somehow vue doesn't correctly read it from route.query so get it manually
      const viewFromUrl = new URLSearchParams(window.location.search).get("view");
      return viewFromUrl || this.$route.query.view || this.$store.state.view;
    },

  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}

  .provider-container {
    display: flex;
    flex-direction: column;

  }

</style>

